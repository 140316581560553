@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap')
@import '../_mixins'

html
    font-size: 62.35%
    scroll-behavior: smooth

body
    margin: 0
    padding: 0

    font-size: 1.4rem
    font-family: 'Inter', sans-serif
    line-height: 1.2
    color: #000018

    background-color: #EFEFEF
    scroll-behavior: smooth

*,
*::before,
*::after
    box-sizing: border-box

h1,h2,h3,h4,h5,h6,p,ol,li,ul
    margin: 0
    padding: 0

p
    margin-block: 1rem

dt
    margin-bottom: 1rem

dd
    margin-bottom: .2rem

ol,ul,li
    list-style: none

a
    color: inherit 
    text-decoration: none
    cursor: pointer

button, a

    &:focus
        outline: none

input
    color: inherit
    border: none

    &:focus
        outline: none

hr
    border-top: 1px solid rgba(0, 0, 0, 0.10)

.img
    display: block
    max-width: 100%
    height: auto


.container
    width: 100%
    max-width: 1440px
    margin: 0 auto
    @include for-sm-only
        padding-inline: 2rem


hr
    height: 1px
    color: #ed1d61
    background-color: #FFFFFF33
    box-shadow: 0 0 0 100vmax #FFFFFF33
    clip-path: inset(0 -100vmax)
    font-size: 0
    border: 0

    margin-block: 2rem


.section 
    padding-block: 8rem

    &--without-padding
        padding-block: 0

    @include for-sm-only
        padding-block: 5rem
        
        &--without-padding
            padding-block: 0!important
            padding-top: 3rem!important

    @include for-phone-only
        padding-block: 3rem
